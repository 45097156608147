<template>
  <div>
    <slot name="header" />
    <div class="container mx-auto max-w-sm">
      <transition
        name="fade"
        mode="out-in">
        <div
          class="my-12"
          v-if="view === 'initial'"
          key="initial">
          <form>
            <error-message v-if="error">
              {{ error }}
            </error-message>

            <input-field
              type="text"
              pattern="[0-9]*"
              inputmode="numeric"
              v-model="formData.phone"
              label="Telefonnummer"
              maxlength="8"
              class="text-center text-lg"
              autofocus
              centered />
            <span class="text-xs text-muted mt-2 mb-4 block">8 siffer</span>

            <default-button
              :loading="submitting"
              @click.prevent.native="submit"
              primary
              class="w-full">
              Send engangskode
            </default-button>
          </form>
        </div>
        <div
          class="my-12"
          v-else-if="view === 'verify'"
          key="verify">
          <success-message>
            Vi har sendt deg en engangskode på SMS til {{ this.formData.phone }}
          </success-message>

          <error-message v-if="error">
            {{ error }}
          </error-message>

          <input-field
            type="text"
            v-model="token"
            pattern="[0-9]*"
            inputmode="numeric"
            maxlength="4"
            label="Engangskode"
            class="text-center text-lg mb-2"
            autocomplete="one-time-code"
            autofocus
            centered />
          <default-button
            :loading="submitting"
            @click.prevent.native="submitToken"
            primary
            class="w-full">
            Verifiser
          </default-button>
          <div class="text-center">
            <a
              v-if="retryEnabled"
              href="#"
              class="p-3 inline-block text-sm text-gray-800 hover:underline"
              @click.prevent="submit">Prøv på nytt</a>
            <a
              v-else
              href="#"
              class="p-3 inline-block text-sm text-gray-800 hover:underline disabled"
              @click.prevent="">
              Det kan ta litt tid før du får engangskode, du kan trykke her for å prøve på nytt om {{ timer }} sekunder.
            </a>
          </div>
        </div>
        <div
          class="my-12"
          v-else-if="view === 'complete'"
          key="complete">
          <success-message>
            Telefonnummeret ditt er bekreftet.
          </success-message>
        </div>
      </transition>
    </div>
    <slot :is-valid="isValid" />
  </div>
</template>

<script>
import validate from "validate.js"
import axios from "axios"

export default {
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },

    formData: {
      type: Object,
      default() {
        return {}
      }
    },

    setFormData: {
      type: Function,
      default() {
        return () => { }
      }
    }
  },

  data() {
    return {
      view: "initial",
      submitting: false,
      token: null,
      error: null,
      retryEnabled: false,
      retryIntervalId: null,
      retryTimerId: null,
      maxRetryTimerSeconds: 20,
      timer: 20
    }
  },

  computed: {
    modelErrors() {
      return validate.single(this.formData.phoneVerificationId, {
        presence: true,
        type: "string"
      })
    },

    isValid() {
      return (typeof this.modelErrors === "undefined")
    }
  },

  methods: {
    submit() {
      this.submitting = true
      this.error = null
      axios.post("/api/verify-phone", { phone: this.formData.phone })
        .then(() => {
          this.submitting = false
          this.view = "verify"
          this.stopRetryTimers()
          this.startRetryTimers()
        })
        .catch(error => {
          this.error = error.response.data.message
          this.submitting = false
        })
    },
    submitToken() {
      this.submitting = true
      this.error = null
      axios.post("/api/verify-phone/token", { token: this.token, phone: this.formData.phone })
        .then(response => {
          this.submitting = false
          this.view = "complete"
          this.setFormData("phoneVerificationId", response.data.data.verification_id)
        })
        .catch(error => {
          this.error = error.response.data.message
          this.submitting = false
        })
    },
    startRetryTimers() {
      this.retryEnabled = false
      this.timer = this.maxRetryTimerSeconds,
      this.retryTimerId = window.setTimeout(() => {
        this.retryEnabled = true
        this.stopRetryTimers()
      }, this.maxRetryTimerSeconds * 1000)
      this.retryIntervalId = window.setInterval(() => {
        this.timer -= 1
      }, 1000)
    },
    stopRetryTimers() {
      window.clearTimeout(this.retryTimerId)
      window.clearInterval(this.retryIntervalId)
    }
  },

  unmounted() {
    this.stopRetryTimers()
  },

  watch: {
    "formData.phone"(value) {
      if (value.length >= 8 && !this.submitting) {
        // this.submit()
      }
    },
    token(value) {
      if (value.length >= 4 && !this.submitting) {
        this.submitToken()
      }
    },
    view(value) {
      if (value === "initial" || value === "complete") {
        this.stopRetryTimers()
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.disabled {
  cursor: not-allowed;
  color: gray;
  pointer-events: none;
}
</style>
